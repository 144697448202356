import React, { useState, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import loadable from "@loadable/component";
import Gs from "./Theme/globalStyles";
import { ThemeProvider } from "styled-components";
import styled from "styled-components";
import { AnimateSharedLayout, AnimatePresence } from "framer-motion";
import { theme } from "./Theme/theme";
// import LoaderGif from "./Assets/images/loading.gif";
import LoaderGif from "./Component/customLoading";
import LegalModal from "./Component/Modals/legalModal";

import { PrivateRoute } from "./views/private.route";
import { PublicRoute } from "./views/public.route";
import { services } from "./services";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "./actions";

const Header = loadable(() => import("./Component/header"));
const Footer = loadable(() => import("./Component/footer"));
const AuthLayout = loadable(() => import("./layouts/auth.layout"));
const UserLayout = loadable(() => import("./layouts/user.layout"));

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;
const DMainContainer = styled(FlexDiv)`
  width: 100%;
  justify-content: flex-start;
  overflow: hidden;
  flex-wrap: wrap;
  position: relative;
  align-content: flex-start;
  flex-direction: column;
`;
const LoaderBX = styled(FlexDiv)`
  width: 100%;
  margin: 50px auto;
`;

function App(props) {
  const [showLegalModal, setShowLegalModal] = useState(false);
  const [isDark, setDarkTheme] = useState(false);
  const [loggedIn, setLoggedIn] = useState(
    localStorage.getItem("avangartAuthToken") ? true : false
  );
  const selectedTheme = theme(isDark);

  function setTheme(flag) {
    setDarkTheme(flag);
  }

  const checkRefLinkExists = new URL(window.location.href).searchParams.get(
    "invited_by"
  );
  if (checkRefLinkExists) {
    services.createCookie("invited_by", checkRefLinkExists, 2);
  }

  const dispatch = useDispatch(actions.getCurrentChainCurrency());
  dispatch(actions.getCurrentChainCurrency());

  useEffect(() => {
    if (!localStorage.getItem("hideLegalModal")) {
      setShowLegalModal(true);
    }
    setDarkTheme(dispatch(actions.toggleStyle()));
  }, [dispatch]);

  return (
    <Router>
      <ThemeProvider theme={selectedTheme}>
        <AnimateSharedLayout>
          <AnimatePresence>
            <section className={`MainBox clearfix`}>
              <Gs.GlobalStyle />
              <DMainContainer>
	  	{showLegalModal && (
                  <LegalModal
                    toggle={() => setShowLegalModal(false)}
                  />
                )}
                <Header loggedIn={loggedIn} />
                <Suspense
                  fallback={
                    <LoaderBX>
                      {/* <img src={LoaderGif} alt="" /> */}
                      <LoaderGif />
                    </LoaderBX>
                  }
                >
                  <Switch>
                    <PrivateRoute
                      path="/user"
                      component={(props) => <UserLayout {...props} />}
                    />
                    <PublicRoute
                      path="/"
                      component={(props) => (
                        <AuthLayout {...props} loggedIn={loggedIn} />
                      )}
                    />
                    {loggedIn ? (
                      <Redirect to="/user" from="/" />
                    ) : (
                      <Redirect from="/user" to="/" />
                    )}
                  </Switch>
                </Suspense>
                <Footer loggedIn={loggedIn} />
              </DMainContainer>
            </section>
          </AnimatePresence>
        </AnimateSharedLayout>
      </ThemeProvider>
    </Router>
  );
}

export default App;
