import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FormattedMessage } from "react-intl";
import { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";

function LegalModal({ toggle, cookies }) {
  const [doNotShowAgain, setDoNotShowAgain] = useState(false);

  useEffect(() => {
    const hideModal = cookies.get("hideLegalModal");
    if (hideModal === "true") {
      toggle(false);
    }
  }, [cookies, toggle]);

  const handleClose = () => {
    if (doNotShowAgain) {
      const expire = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000);
      cookies.set("hideLegalModal", "true", { path: "/", expires: expire });
    }
    toggle(false);
  };

  return (
    <BlackWrap>
      <WhiteBX01>
        <OnbTitle01>
          <FormattedMessage
            id="legal_information"
            defaultMessage="Legal Information"
          />
        </OnbTitle01>
        <OnbText01>
          <FormattedMessage
            id="legal_information_message"
            defaultMessage="NFT and game tokens are not included in the listing rules of the Capital Markets Law."
          />
        </OnbText01>
        <CheckboxContainer>
          <input
            type="checkbox"
            id="doNotShowAgain"
            checked={doNotShowAgain}
            onChange={() => setDoNotShowAgain(!doNotShowAgain)}
          />
          <label htmlFor="doNotShowAgain">
            <FormattedMessage id="agree_with_the_terms" defaultMessage="I agree with the terms" />
          </label>
        </CheckboxContainer>
        <InstallBtn
          className="ani-1"
          onClick={handleClose}
          disabled={!doNotShowAgain}
        >
          <FormattedMessage id="continue" defaultMessage="Continue" />
        </InstallBtn>
      </WhiteBX01>
    </BlackWrap>
  );
}

LegalModal.propTypes = {
  cookies: instanceOf(Cookies).isRequired,
};

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const OnbTitle01 = styled.div`
  font-size: 22px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
  letter-spacing: -1px;
  &.v2 {
    max-width: 220px;
    margin: 0 auto;
    text-align: center;
    line-height: 28px;
  }
`;

const OnbText01 = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000;
  letter-spacing: -0.5px;
  &.text-center {
    text-align: center;
    width: 100%;
    margin: 20px 0px 40px;
  }
`;

const CheckboxContainer = styled(FlexDiv)`
  margin: 20px 0;
  input {
    margin-right: 10px;
  }
`;

const InstallBtn = styled.button`
  background-color: #000000;
  border: 1px solid #000;
  color: #fff;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: 700;
  border-radius: 15px;
  margin: 0 auto;
  padding: 17px 15px;
  width: 100%;
  :hover {
    background-color: #fff;
    color: #000;
  }
  :disabled {
    background-color: #888;
    color: #fff;
    cursor: not-allowed;
  }
`;

const BlackWrap = styled(FlexDiv)`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 101;
  backdrop-filter: blur(2px);
`;

const WhiteBX01 = styled(FlexDiv)`
  width: 100%;
  position: relative;
  max-width: 400px;
  margin: 0 15px;
  min-height: 200px;
  padding: 50px;
  background-color: #fff;
  border-radius: 30px;
  justify-content: flex-start;
  align-content: center;
`;

export default withCookies(LegalModal);
